<template>
  <div>
    <FormContainer>
      <template #title>
        <span
          v-if="showTitle"
          class="text-body-medium text-center text-white opacity-50 ltr:tracking-[0.72px] rtl:tracking-normal"
        >
          {{ $t("stepper", { step: 2, of: 3 }) }}
        </span>
      </template>
      <template #subtitle>
        <h1
          class="text-display-small md:text-headline-big text-white opacity-[0.87] text-center ltr:tracking-[2.67px] rtl:tracking-normal"
        >
          {{ $t("Provide Your Details") }}
        </h1>
      </template>
      <form
        novalidate
        @submit.prevent="onSubmit"
        @invalid-submit.prevent="onInvalidSubmit"
        class="flex flex-col gap-y-6 justify-center"
      >
        <FormInput
          :label="t('Confirm email')"
          :placeholder="t('Your email')"
          name="username"
        ></FormInput>
        <FormInput
          :label="t('First name')"
          :placeholder="t('Your first name')"
          name="first_name"
        ></FormInput>
        <FormInput
          :label="t('Last name')"
          :placeholder="t('Your last name')"
          name="last_name"
        ></FormInput>

        <FormBooleanToggle
          :label="t('Gender')"
          :options="genderOptions"
          name="gender"
          v-model="selectedGender"
        >
        </FormBooleanToggle>
        <div class="flex flex-col gap-y-2">
          <label
            for="phone"
            class="text-button-small text-white opacity-[0.87] ltr:tracking-[0.6px] rtl:tracking-normal"
          >
            {{ $t("whereWatchFrom") }}
          </label>
          <UiCountrySelect
            :options="countries"
            :labelKey="selectorLanguageKey"
            :searchKey="[selectorLanguageKey]"
            :wrap-class="'rounded-e-none'"
            id="countryselect"
            v-model:selectedOption="getStartedStore.createForm.location"
            class="w-full"
          >
            <template #on-empty>
              <div class="flex items-center w-full h-20 py-4 px-4 relative">
                <Icon
                  class="me-4 flex-shrink-0 relative self-start"
                  name="mingcute:warning-line"
                  size="24"
                ></Icon>
                <div class="text-base leading-normal break-words">
                  {{ $t("nonExistCountry") }}
                </div>
              </div>
            </template>
          </UiCountrySelect>
        </div>

        <div class="flex flex-col gap-y-2">
          <label
            for="phone"
            class="text-button-small text-white opacity-[0.87] ltr:tracking-[0.6px] rtl:tracking-normal"
          >
            {{ $t("Mobile number") }}
          </label>
          <div
            class="nationality-wrapper flex relative justify-center items-center"
          >
            <UiCountrySelect
              :options="countries"
              :labelKey="selectorLanguageKey"
              :searchKey="['dialCode', selectorLanguageKey]"
              :wrap-class="'rounded-e-none'"
              :country-icon="!isXs"
              countryCode
              id="countryselect"
              class="w-[47%]"
              v-model:selectedOption="getStartedStore.createForm.country_code"
            >
              <template #on-empty>
                <div class="flex items-center w-full h-20 py-4 px-4 relative">
                  <Icon
                    class="me-4 flex-shrink-0 relative self-start"
                    name="mingcute:warning-line"
                    size="24"
                  ></Icon>
                  <div class="text-base leading-normal break-words">
                    {{ $t("nonExistCountryCode") }}
                  </div>
                </div>
              </template>
            </UiCountrySelect>
            <div class="separator w-[1px] py-3 h-full bg-white/20"></div>
            <UiInput
              :fail="fail"
              :maxlength="15"
              :extraClass="'rounded-s-none'"
              :placeholder="$t('phoneNumber')"
              type="number"
              isClear
              class="nationality-wrapper-input flex-1"
              v-model="getStartedStore.createForm.phone"
            ></UiInput>
          </div>
        </div>
        <div class="flex gap-x-2">
          <FormCheckbox name="agreeToTerms"></FormCheckbox>
          <div class="flex flex-col text-start">
            <p class="text-body-medium text-white/50 tracking-[0.42px]">
              {{ $t("greeTo") }}
              <NuxtLink
                :to="localePath('/terms')"
                target="_blank"
                class="text-white"
              >
                {{ $t("terms") }} </NuxtLink
              >,
              <NuxtLink
                :to="localePath('/privacy')"
                target="_blank"
                class="text-white"
              >
                {{ $t("privacyPolicy") }} </NuxtLink
              >, {{ $t("and") }}
              <NuxtLink
                :to="localePath('/static/cookie-policy')"
                target="_blank"
                class="text-white"
              >
                {{ $t("cookiePolicy") }} </NuxtLink
              >.
            </p>
          </div>
        </div>
        <FormCheckbox name="agreeToMarketing">
          <div class="flex flex-col text-start">
            <p class="text-body-medium text-white opacity-50 tracking-[0.42px]">
              {{ $t("agreeMarketing") }}
            </p>
          </div>
        </FormCheckbox>

        <ButtonsCta
          :is-loading="isSubmitting"
          :disabled="!formComplete"
          class="variant-primary"
        >
          {{ $t("Continue") }}
        </ButtonsCta>
      </form>
    </FormContainer>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";
import { useGetStartedStore } from "@/stores/getStarted";
import countries from "@/public/static/countries";
import { useForm } from "vee-validate";
import useSigninAuth from "@/composables/useSigninAuth";

const { useValidPhone } = useLibs();
const sessionStore = useSessionStore();
const getStartedStore = useGetStartedStore();
const isSubmitting = ref(false);
const { isXs } = useBreakpoints();
const { onListen } = useKeyBoardEvent("enter");
const { authorize, navigatePostAuth } = useSigninAuth();
const { updateProfileColor } = useProfilesApi();
useFormRules();

const props = defineProps({
  standalone: Boolean,
  showTitle: Boolean,
});

const { t, locale } = useI18n();

const selectorLanguageKey = ref("");
selectorLanguageKey.value =
  locale.value == "ar" ? "noCodeNameAr" : "noCodeNameEn";

const genderOptions = computed(() => [
  {
    label: t("Male"),
    value: 1,
    desc: "male",
  },
  {
    label: t("Female"),
    value: 2,
    desc: "female",
  },
]);

const schema = {
  first_name: "required",
  last_name: "required",
  username: "required|email",
  agreeToTerms: "isTrue",
};

const selectedGender = ref(1);
const form = useForm({
  validationSchema: schema,
  initialValues: {
    username: getStartedStore.createForm.username,
    first_name: getStartedStore.createForm.first_name,
    last_name: getStartedStore.createForm.last_name,
    gender: 1,
  },
});

const formComplete = computed(() => {
  const isValid = form.meta.value.valid;
  const { phone, location } = getStartedStore.createForm;
  const isValidPhone = /^\d{8,15}$/.test(phone);
  const otherFields = !!(((phone && isValidPhone) || !phone) && location);
  return isValid && otherFields;
});

const fail = computed(() => {
  return !(
    useValidPhone(getStartedStore.createForm.phone) ||
    !getStartedStore.createForm.phone
  );
});

const onInvalidSubmit = () => {};

const onSubmit = async () => {
  isSubmitting.value = true;

  getStartedStore.$patch((state) => {
    Object.assign(state.createForm, { ...form.values });
  });

  if (!sessionStore.identifierHeaders.serialNumber) {
    sessionStore.generateIdentifiers(form.values.username);
  }

  const payload = {
    country_code: getStartedStore.createForm.country_code.dialCode?.replace(
      "+",
      ""
    ),
    location: getStartedStore.createForm.location.isoNation,
    phone_number: getStartedStore.createForm.phone,
    gender: genderOptions.value.find(
      (it) => it.value == selectedGender.value
    )?.desc,
    username: form.values.username,
    first_name: form.values.first_name,
    last_name: form.values.last_name,
  };
  let extOptions = {
    headers: {
      "Serial-Number": sessionStore.identifiers?.serialNumber,
      "Terminal-Name": sessionStore.identifiers?.terminalName,
    },
  };
  let path;
  if (Object.values(getStartedStore.ssoResponse).every((v) => v !== null)) {
    path = "/api/uc/manage/register/sso";
    Object.assign(payload, {
      ...getStartedStore.ssoResponse,
      ...{
        token_type: getStartedStore.ssoResponse.tokenType,
      },
    });
  } else {
    path = "/api/uc/manage/register";
    payload.password = getStartedStore.createForm.password;
  }

  try {
    const res = await useRequest().post(path, payload, extOptions);
    if (res?.response) {
      /**
       * Add profile data from SSO response to store to make it available for API calls
       * profileForm
       */
      if (getStartedStore?.ssoResponse?.token) {
        const { user_profiles } = res?.response;
        const profiles = updateProfileColor(user_profiles);
        const [activeProfile] = profiles;
        sessionStore.$patch({
          profiles,
          activeProfile,
          profileForm: activeProfile,
        });
      }

      res?.response && authorize(res.response);
      await navigatePostAuth({ step: "recommendations" });
    }
  } catch (error) {
    const { openToast } = useToast();
    await openToast({ content: error.message, type: "error" });
    return;
  }

  isSubmitting.value = false;

  getStartedStore.$patch((state) => {
    state.step = "createCodeSent";
  });
};

onListen(() => formComplete.value && onSubmit());

watch(
  () => getStartedStore.createForm.location,
  (newVal) => {
    getStartedStore.$patch({
      createForm: Object.assign({
        ...getStartedStore.createForm,
        country_code: newVal,
      }),
    });
  },
  { deep: true }
);
</script>

<script>
export default { name: "CreateDetails" };
</script>

<style lang="scss" scoped></style>
