<template>
  <div>
    <FormContainer>
      <template #title>
        <span
          class="text-body-medium text-center text-white opacity-50 ltr:tracking-[0.72px] rtl:tracking-normal"
        >
          {{ $t("stepper", { step: 3, of: 3 }) }}
        </span>
      </template>
      <template #subtitle>
        <h1
          class="text-display-small md:text-headline-big text-white opacity-[0.87] text-center ltr:tracking-[2.67px] rtl:tracking-normal"
        >
          {{ $t("Code Sent") }}
        </h1>
      </template>
      <div class="flex flex-col gap-y-10 text-white">
        <div class="flex flex-col gap-y-2">
          <span class="text-body-medium text-center text-white opacity-[0.87]">
            {{ $t("We’ve sent a verification code to") }}
          </span>
          <h2 class="text-title-medium text-center text-white opacity-[0.87]">
            {{ getStartedStore.createForm.username }}
          </h2>
        </div>
        <div class="flex flex-col gap-y-5">
          <span class="text-body-medium text-center">
            {{ $t("codeWillExpireIn", { hours: 12 }) }}
          </span>

          <ButtonsCta
            :disabled="disabled"
            class="variant-secondary outlined w-fit mx-auto"
            @click="onResendCode"
          >
            {{ $t("resendCodeIn", { count: limit }) }}
          </ButtonsCta>
        </div>
        <div
          class="mx-auto w-[336px] sm:w-[336px] xl:w-[32rem] h-[1px] bg-white/20"
        ></div>
        <div class="text-center">{{ $t("Enter code to verify") }}</div>
        <div class="flex flex-col gap-y-2">
          <v-otp-input
            dir="ltr"
            key="otp-numeric"
            :num-inputs="6"
            :should-auto-focus="true"
            input-type="numeric"
            ref="otpInput"
            v-model:value="otp"
            input-classes="otp-input flex justify-center items-center outline-none text-center text-white opacity-[0.87] text-headline-small rounded-[4px] h-20 w-10 xl:w-[72px] aspect-square border border-transparent bg-white/10 hover:bg-white/[0.15] focus:border-white/80 "
            separator=""
            class="mx-auto w-[336px] sm:w-[336px] xl:w-[32rem] justify-center gap-x-3 sm:justify-between xl:gap-x-4"
            :class="{ 'opt-input-err': codeErr }"
            @on-change="onOTPChange"
            @on-complete="onOTPComplete"
          ></v-otp-input>
          <div v-if="codeErr" class="text-brand-red-error text-label-small">
            {{ $t("codeError") }}
          </div>
        </div>

        <ButtonsCta
          :disabled="!isComplete || codeErr"
          class="variant-primary outlined w-[285px] sm:w-[336px] mx-auto"
          @click="fetchVerificationStatus"
        >
          {{ $t("Continue") }}
        </ButtonsCta>
      </div>
    </FormContainer>
  </div>
</template>

<script setup>
import VOtpInput from "vue3-otp-input";
import { useInterval, useTimeoutPoll } from "@vueuse/core";
import { useGetStartedStore } from "@/stores/getStarted";
import { useSessionStore } from "@/stores/session";
import { RESPONSE_STATUS } from "@/constants";
const { signupSuccess } = useADtvGTM();
const { useDelCancelAutoFocus } = useLibs();

const props = defineProps({
  standalone: Boolean,
  showTitle: Boolean,
});

const { openToast } = useToast();
const { updateProfileColor } = useProfilesApi();
const { t, locale } = useI18n();
const sessionStore = useSessionStore();
const emit = defineEmits("onComplete");
const { setLang } = useSetLang();
const localePath = useLocalePath();
const getStartedStore = useGetStartedStore();

const otpInput = ref();
const otp = ref();
const isComplete = ref(false);
const codeErr = ref(false);

const onOTPChange = (value) => {
  console.log("onOTPChange", codeErr.value, value);
  console.log("onOTPChange");
  codeErr.value = false;
  useDelCancelAutoFocus(otpInput, value);
  if (value.length < 6) {
    isComplete.value = false;
  }
  !value && (codeErr.value = false);
};
const onOTPComplete = () => {
  isComplete.value = true;
};

// If the parameter exists, it is in registration mode
const isRegister = computed(() => {
  const { gender, first_name, last_name } = getStartedStore.createForm;
  return gender && first_name && last_name;
});

// countdown mechanism
const disabled = computed(() => {
  return limit.value > 0;
});

const limit = ref(isRegister.value ? 90 : 0);

const {
  counter,
  reset,
  resume: resumeCountdown,
} = useInterval(1000, { controls: true, immediate: isRegister.value });

watch(
  () => counter.value,
  () => {
    if (limit.value === 0) {
      reset();
      resumeCountdown();
    } else {
      limit.value -= 1;
    }
  }
);

const onResendCode = async () => {
  const res = await useGetMethod(
    "/api/uc/manage/resend",
    {
      username: getStartedStore.createForm.username,
    },
    { raw: true }
  );
  const { code, status, msg } = res;
  if (code == "SUCCESS" && status == 200) {
    openToast({ content: t("emailSend"), type: "loading" });
    limit.value = 90;
    reset();
    resumeCountdown();
  } else {
    openToast({ content: msg, type: "error" });
  }
};

const updateGTM = (account) => {
  if (getStartedStore?.createForm?.phone) {
    signupSuccess("phone number", account);
  } else {
    signupSuccess("email", account);
  }
};

async function fetchVerificationStatus() {
  const { username, password, gender, first_name, last_name } =
    getStartedStore.createForm;
  const params = {
    username,
    password,
    gender,
    firstName: first_name,
    lastName: last_name,
    otp: otp.value,
  };

  const payload = {
    body: {
      language_code: locale.value,
      model_external_id: "PC",
      ...params,
    },
    headers: {
      "Serial-Number": sessionStore.identifiers?.serialNumber,
      "Terminal-Name": sessionStore.identifiers?.terminalName,
    },
  };

  const res = await usePostMethod("/api/uc/user/login", payload);
  const { code, response, status, msg } = res;
  if (code == "FAILURE") {
    codeErr.value = true;
    return;
  }

  const { token, key, user_profiles, househould_id } = response;
  updateGTM(househould_id);
  const profiles = updateProfileColor(user_profiles);
  const [activeProfile] = profiles;
  sessionStore.$patch({
    token,
    key,
    profiles,
    activeProfile,
    profileForm: activeProfile,
  });
  getStartedStore.closeModal();
  getStartedStore.$reset();
  await setLang(activeProfile?.property?.language);

  await useFmaSdk().forceInit({
    serialNumber: sessionStore.identifiers?.serialNumber,
    terminalId: sessionStore.identifiers?.terminalName,
  });

  //Code exists on the jump verification code input page
  if (sessionStore.code) {
    try {
      await useTvLink().confirm(sessionStore.code);
    } catch (error) {
      let errorMsg = error;
      if (error == "WRONG_TV_CODE") {
        errorMsg = t(
          "The code entered is either invalid or expired. Please try again."
        );
      }
      openToast({
        content: errorMsg,
        type: "error",
      });

      return navigateTo({
        path: localePath("/link-tv"),
        query: { retry: true },
        external: true,
        replace: true,
      });
    }
  }

  console.log("navigateTo profiles");
  navigateTo({
    path: localePath("/profiles"),
    query: { step: "recommendations" },
  });
}

onMounted(() => {
  // resumePoll();
});
</script>

<script>
export default { name: "Otp" };
</script>

<style lang="scss" scoped></style>
