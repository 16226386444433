<template>
  <div>
    <FormContainer>
      <template #title>
        <span
          class="text-body-medium text-center text-white opacity-50 ltr:tracking-[0.72px] rtl:tracking-normal"
        >
          {{ $t("stepper", { step: 3, of: 3 }) }}
        </span>
      </template>
      <template #subtitle>
        <h1
          class="text-display-small md:text-headline-big text-white opacity-[0.87] text-center ltr:tracking-[2.67px] rtl:tracking-normal"
        >
          {{ $t("Link Sent") }}
        </h1>
      </template>
      <div class="flex flex-col gap-y-10">
        <div class="flex flex-col gap-y-2">
          <span class="text-body-medium text-center text-white opacity-[0.87]">
            {{ $t("We’ve sent a verification link to") }}
          </span>
          <h2 class="text-title-medium text-center text-white opacity-[0.87]">
            {{ getStartedStore.createForm.username }}
          </h2>
        </div>
        <div class="flex flex-col gap-y-5">
          <span class="text-body-medium text-center">
            {{ $t("willExpireIn", { hours: 24 }) }}
          </span>

          <ButtonsCta
            :disabled="disabled"
            class="variant-secondary outlined w-fit mx-auto"
            @click="onResendLink"
          >
            {{ $t("resendIn", { count: limit }) }}
          </ButtonsCta>
        </div>
      </div>
    </FormContainer>
  </div>
</template>

<script setup>
import { useInterval, useTimeoutPoll } from "@vueuse/core";
import { useGetStartedStore } from "@/stores/getStarted";
import { useSessionStore } from "@/stores/session";
const { signupSuccess } = useADtvGTM();

const props = defineProps({
  standalone: Boolean,
  showTitle: Boolean,
});

const { openToast } = useToast();
const { updateProfileColor } = useProfilesApi();
const { t } = useI18n();
const sessionStore = useSessionStore();
const emit = defineEmits("onComplete");
const { setLang } = useSetLang();

const localePath = useLocalePath();

const getStartedStore = useGetStartedStore();

// If the parameter exists, it is in registration mode
const isRegister = computed(() => {
  const { gender, first_name, last_name } = getStartedStore.createForm;
  return gender && first_name && last_name;
});

// countdown mechanism
const disabled = computed(() => {
  return limit.value > 0;
});

const limit = ref(isRegister.value ? 60 : 0);
const {
  counter,
  reset,
  resume: resumeCountdown,
} = useInterval(1000, { controls: true, immediate: isRegister.value });

watch(
  () => counter.value,
  () => {
    if (limit.value === 0) {
      reset();
      return;
    }
    limit.value -= 1;
  }
);

const onResendLink = async () => {
  const res = await useGetMethod(
    "/api/uc/manage/resend",
    {
      username: getStartedStore.createForm.username,
    },
    { raw: true }
  );
  const { code, status, msg } = res;
  if (code == "SUCCESS" && status == 200) {
    openToast({ content: t("emailSend"), type: "loading" });
    limit.value = 60;
    reset();
    resumeCountdown();
  } else {
    openToast({ content: msg, type: "error" });
  }
};

const updateGTM = (account) => {
  if (getStartedStore?.createForm?.phone) {
    signupSuccess("phone number", account);
  } else {
    signupSuccess("email", account);
  }
};

// polling mechanism
const { resume: resumePoll } = useTimeoutPoll(fetchVerificationStatus, 5000);

async function fetchVerificationStatus() {
  const { username, password, gender, first_name, last_name } =
    getStartedStore.createForm;
  const params = isRegister.value
    ? {
        username,
        password,
        gender,
        firstName: first_name,
        lastName: last_name,
      }
    : {
        username,
        password,
      };
  const res = await usePostMethod("/api/uc/manage/registerStatus", {
    raw: true,
    headers: {
      ...sessionStore.identifierHeaders,
    },
    body: params,
  });

  const { code, response } = res;
  if (code == "FAILURE") {
    return;
  }

  const { token, key, user_profiles, househould_id } = response;
  updateGTM(househould_id);
  const profiles = updateProfileColor(user_profiles);
  const [activeProfile] = profiles;
  sessionStore.$patch({
    token,
    key,
    profiles,
    activeProfile,
    profileForm: activeProfile,
  });

  getStartedStore.closeModal();
  getStartedStore.$reset();
  await setLang(activeProfile?.property?.language);

  await useFmaSdk().forceInit({
    serialNumber: sessionStore.identifiers?.serialNumber,
    terminalId: sessionStore.identifiers?.terminalName,
  });

  //Code exists on the jump verification code input page
  if (sessionStore.code) {
    try {
      await useTvLink().confirm(sessionStore.code);
    } catch (error) {
      let errorMsg = error;
      if (error == "WRONG_TV_CODE") {
        errorMsg = t(
          "The code entered is either invalid or expired. Please try again."
        );
      }
      openToast({
        content: errorMsg,
        type: "error",
      });

      return navigateTo({
        path: localePath("/link-tv"),
        query: { retry: true },
        external: true,
        replace: true,
      });
    }
  }
  console.log("navigateTo profiles");
  navigateTo({
    path: localePath("/profiles"),
    query: { step: "recommendations" },
  });
}

onMounted(() => {
  resumePoll();
});
</script>

<script>
export default { name: "LinkSent" };
</script>

<style lang="scss" scoped></style>
